<template>
  <div class="card">
    <div class="card-body">
      <div v-if="item">
        <button class="btn btn-primary mb-4" @click="edit = true" v-if="!edit">
          Update Info
        </button>
        <div class="input-group d-flex flex-column mb-4" v-if="!edit">
          <label>Username:</label>
          <input
            type="text"
            class="form-control"
            aria-describedby="button-addon2"
            v-model="item.username"
            :readonly="!edit"
          />
        </div>
        <div
          class="input-group d-flex flex-column mb-4"
          v-if="item.firstname || edit"
        >
          <label>First Name:</label>
          <input
            type="text"
            class="form-control"
            aria-describedby="button-addon2"
            v-model="item.firstname"
            :readonly="!edit"
          />
        </div>
        <div
          class="input-group d-flex flex-column mb-4"
          v-if="item.lastname || edit"
        >
          <label>Last Name:</label>
           <input
            type="text"
            class="form-control"
            aria-describedby="button-addon2"
            v-model="item.lastname"
            :readonly="!edit"
          />
        </div>
        <div
          class="input-group d-flex flex-column mb-4"
          v-if="!edit"
        >
          <label>Email:</label>
          <input
            type="email"
            class="form-control"
            aria-describedby="button-addon2"
            v-model="item.email"
            :readonly="true"
          />
          <p class="email-error-msg" v-if="showEmailErrorMsg">{{ emailErrorMsg }}</p>
        </div>
        <div
          class="input-group d-flex flex-column mb-4"
          v-if="item.async_limit && !edit"
        >
          <label>Limit:</label>
           <input
            type="text"
            class="form-control"
            aria-describedby="button-addon2"
            v-model="item.async_limit"
            :readonly="!edit"
          />
        </div>
        <div
          class="input-group d-flex flex-column mb-4"
          v-if="item.credits && !edit"
        >
          <label>Credits:</label>
          <input
            type="text"
            class="form-control"
            aria-describedby="button-addon2"
            v-model="item.credits"
            :readonly="!edit"
          />
        </div>
        <div class="input-group d-flex flex-column mb-4" v-if="edit">
          <label>Password</label>
          <input
            type="text"
            class="form-control"
            aria-describedby="button-addon2"
            v-model="item.password"
            :readonly="!edit"
          />
        </div>
        <div class="d-flex justify-content-end" v-if="edit">
          <button class="btn btn-danger" @click="cancel">Cancel</button>

          <button class="btn btn-success" @click="save">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      edit: false,
      item: null,
      itemDefault: null,
      showEmailErrorMsg: false,
      emailErrorMsg: ''
    };
  },
  async mounted() {
    await this.$store.dispatch('loadUserInfo');
    this.item = Object.assign({}, this.$store.state.user);
    this.itemDefault = this.item;
  },
  methods: {
    cancel() {
      this.item = Object.assign({}, this.itemDefault);
      this.edit = false;
    },
    save() {
      let params = this.item;
      params['user_id'] = this.item.id

      if (this.item.firstname) {
        params["firstname"] = this.item.firstname;
      }
      if (this.item.lastname) {
        params["lastname"] = this.item.lastname;
      }
      if (this.item.password) {
        params["password"] = this.item.password;
      }

      if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(params.email)) {

        this.emailErrorMsg = ''
        this.showEmailErrorMsg = false

        let oldEmailValue = this.item.email
        this.$delete(params, 'email')

        this.$axios
          .post(`${this.$store.state.admin}/user/update`, params)
          .then((res) => {
            this.edit = false;
            this.item.email = oldEmailValue
            this.$swal.fire("Updated!", res.data.message, "success");
          })
          .catch((err) => {
            console.log(err);
            this.$swal.fire("Error!", err.response.data.message, "error");
          });
      }else {
        this.emailErrorMsg = 'Please enter correct email address!'
        this.showEmailErrorMsg = true
      }
    },
  },
};
</script>
<style scoped>
/* label {
  margin-bottom: 0.5rem !important;
} */
.form-control {
  width: 100%;
}
.email-error-msg {
  color: #e94437;
  margin-top: 10px;
  padding-left: 10px;
}
div {
  line-height: 100%;
}
</style>